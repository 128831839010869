export default [
  {
    header: 'Orders',
    action: 'read',
    resource: 'order',
  },
  {
    title: 'Overzicht',
    route: 'app-order',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'order',
  },
  {
    title: 'Order aanmaken',
    route: 'apps-order-flow-checkout',
    icon: 'ShoppingCartIcon',
    action: 'create',
    resource: 'order',
  },
  {
    title: 'Premiecalculator',
    route: 'app-premium-calculator',
    icon: 'SlidersIcon',
    action: 'read',
    resource: 'calculator',
  },
  {
    title: 'Prolongatie',
    route: 'app-prolongations',
    icon: 'SendIcon',
    action: 'read',
    resource: 'prolongatie',
  },
  {
    title: 'Royementen',
    route: 'app-disbarments',
    icon: 'StopCircleIcon',
    action: 'read',
    resource: 'royementen',
  },
  {
    title: 'Claims',
    route: 'app-claim',
    icon: 'BriefcaseIcon',
    action: 'update',
    resource: 'claim',
  },
  {
    title: 'Collectief',
    route: 'app-collectives-list',
    icon: 'UmbrellaIcon',
    action: 'read',
    resource: 'collective',
  },
]
