export default [
  {
    header: 'Producten',
  },
  {
    title: 'Product Overzicht',
    route: 'apps-products-list',
    icon: 'PackageIcon',
    action: 'read',
    resource: 'product',
  },
  {
    title: 'Product Categorien',
    route: 'app-category-list',
    icon: 'GridIcon',
    action: 'read',
    resource: 'category',
  },
]
