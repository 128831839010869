<template v-if="statistics">
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      :statistics="statistics"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { onUnmounted, provide, ref } from '@vue/composition-api'
import store from '@/store'
import layoutVerticalStoreModule from '@core/layouts/layout-vertical/layoutVerticalStoreModule'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    fetchStatistics() {
      store
        .dispatch('app-menu-statistics/fetchMenuStatistics')
        .then(response => {
          this.$store.commit('app/UPDATE_MENU_STATISTICS', response.data)
        })
    },
  },
  computed: {
    statistics() {
      return this.$store.getters['app/getMenuStatistics']
    },
  },
  created() {
    this.fetchStatistics()
  },
  setup() {
    provide('openGroups', ref([]))

    const MENU_STATISTICS_APP_STORE_MODULE_NAME = 'app-menu-statistics'

    // Register module
    if (!store.hasModule(MENU_STATISTICS_APP_STORE_MODULE_NAME)) store.registerModule(MENU_STATISTICS_APP_STORE_MODULE_NAME, layoutVerticalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MENU_STATISTICS_APP_STORE_MODULE_NAME)) store.unregisterModule(MENU_STATISTICS_APP_STORE_MODULE_NAME)
    })

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
