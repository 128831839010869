<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
      <b-badge
        v-if="hasStatistics(item.route).show"
        pill
        variant="secondary"
        class="mr-1 ml-auto"
      >
        {{ hasStatistics(item.route).badge }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    statistics: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    hasStatistics(route) {
      if (route === 'app-order') {
        return { show: this.statistics.orders > 0, badge: this.statistics.orders }
      }

      if (route === 'app-disbarments') {
        return { show: this.statistics.disbarments > 0, badge: this.statistics.disbarments }
      }

      if (route === 'app-prolongations') {
        return { show: this.statistics.prolongations > 0, badge: this.statistics.prolongations }
      }

      if (route === 'app-claim') {
        return { show: this.statistics.claims > 0, badge: this.statistics.claims }
      }

      return { show: false }
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
