export default [
  {
    header: 'Configuratie',
    action: 'read',
    resource: 'account',
  },
  {
    title: 'Account',
    route: 'account-setting',
    icon: 'UserIcon',
    action: 'read',
    resource: 'account',
  },
  {
    title: 'Organisaties',
    route: 'apps-organisations',
    icon: 'MapPinIcon',
    action: 'read',
    resource: 'organisation',
  },
  {
    title: 'Locaties',
    route: 'app-locations-list',
    icon: 'MapIcon',
    action: 'read',
    resource: 'organisation',
  },
  {
    title: 'Gebruikers',
    route: 'apps-users-list',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'user',
  },
  {
    title: 'Audit log',
    route: 'apps-log',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'audit',
  },
]
