export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Overzicht',
        route: 'app-dashboard',
        action: 'read',
        resource: 'dashboard',
      },
      {
        title: 'Financieel',
        route: 'app-dashboard-overview-financial',
        action: 'read',
        resource: 'dashboard-finance',
      },
      {
        title: 'Financieel detail',
        route: 'app-dashboard-specification-financial',
        action: 'read',
        resource: 'dashboard-finance',
      },
    ],
  },
]
