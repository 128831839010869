export default [
  {
    header: 'Export',
    action: 'read',
    resource: 'reports',
  },
  {
    title: 'Rapportage',
    route: 'apps-reports',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'reports',
  },
]
