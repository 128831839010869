<template>
  <div v-if="notifications.length > 0">
    <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    ref="dropdownNotifications"
  >
    <template #button-content
    >
      <feather-icon
        :badge="notificationsAmount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificaties
        </h4>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
        <b-link
          v-for="notification in notifications"
          :key="notification.id"
          @click="updateNotification(notification)"
        >
          <b-media>
            <template #aside>
              <b-avatar
                  size="32"
                  :variant="resolveCategory(notification.category).type"
              >
                <feather-icon :icon="resolveCategory(notification.category).icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.text }}</small>
          </b-media>
        </b-link>
    </vue-perfect-scrollbar>
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="updateAllNotifications"
      >
        <b-spinner
            small
            class="mr-1"
            v-if="showButtonSpinner"
        />
        Markeer alle als gelezen
      </b-button>
    </li>
  </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BLink, BMedia, BNavItemDropdown, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      notificationsAmount: 0,
      interval: null,
      show: false,
      showButtonSpinner: false,
    }
  },
  methods: {
    updateNotification(data) {
      store
        .dispatch('app-notifications/updateNotification', { id: data.id, read: 1 })
        .then(response => {
          this.notifications = [...response.data.items]
          this.notificationsAmount = this.notifications && this.notifications.length ? this.notifications.length : 0
          this.$refs.dropdownNotifications.hide(true)
          this.$router.push({ path: `${data.href}` })
        })
    },
    updateAllNotifications() {
      this.showButtonSpinner = true
      store
        .dispatch('app-notifications/markAllNotificationsRead')
        .then(() => {
          this.showButtonSpinner = false
          this.notifications = []
          this.notificationsAmount = 0
        })
        .catch(() => {
          this.showButtonSpinner = false
        })
    },
    getNotifications() {
      store
        .dispatch('app-notifications/fetchNotifications', {
          sortDesc: false,
          read: 0,
        })
        .then(response => {
          this.notifications = response.data.items
          this.notificationsAmount = this.notifications && this.notifications.length ? this.notifications.length : 0
        })
        .catch(() => {
          this.notifications = []
        })
    },
    resolveCategory(category) {
      switch (category) {
        case 2: {
          // CATEGORY_ORDER_SUCCESS
          return { icon: 'ShoppingCartIcon', type: 'light-success' }
        }
        case 3: {
          // CATEGORY_ORDER_WARNING
          return { icon: 'ShoppingCartIcon', type: 'light-info' }
        }
        case 4: {
          // CATEGORY_ORDER_DANGER
          return { icon: 'ShoppingCartIcon', type: 'light-danger' }
        }
        case 5: {
          // CATEGORY_PROLONGATION_SUCCESS
          return { icon: 'SendIcon', type: 'light-success' }
        }
        case 6: {
          // CATEGORY_PROLONGATION_DANGER
          return { icon: 'SendIcon', type: 'light-danger' }
        }
        default: {
          // CATEGORY_NONE
          return { icon: 'InfoIcon', type: 'light-primary' }
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.getNotifications()
    this.interval = setInterval(this.getNotifications, 300000)
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
