export default [
  {
    header: 'CRM',
    action: 'read',
    resource: 'relation',
  },
  {
    title: 'Relaties',
    route: 'apps-relations-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'relation',
  },
]
