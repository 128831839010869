export default [
  {
    header: 'Financieel',
    action: 'read',
    resource: 'invoice',
  },
  {
    title: 'Commissie plannen',
    route: 'app-commission-plan',
    icon: 'AwardIcon',
    action: 'read',
    resource: 'commission-plan',
  },
  {
    title: 'Facturen',
    route: 'app-invoices',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'invoice',
  },
  {
    title: 'Kwitantie',
    route: 'app-receipts',
    icon: 'ClipboardIcon',
    action: 'read',
    resource: 'receipt',
  },
]
